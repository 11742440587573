<template>
  <div ref="scrollTarget"></div>
  <div class="container-fluid" style="height: 100%;">
    <div class="row">
      <!-- Left Side Menu -->
      <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar">
        <div class="position-sticky" style="margin-top: 50px;">
          <ul class="nav flex-column" style="padding-left: 5%; margin-top: 10px;">
            <router-link to="/dashboard" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash1.png" class="img-item"
                  style="margin-right: 12px;">
                {{
                  translate('dashboard')
                }}</li>
            </router-link>
            <router-link to="/orders-validate" style="color: #000;">
              <li class="nav-item custom active-item"><img src="../assets/img/wait_icon.svg" class="img-item"> {{
                translate('shipments_to_validate') }} <span v-if="pending_orders_notification > 0">({{
                  pending_orders_notification }})</span>
              </li>
            </router-link>
            <router-link to="/progress-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/progress_icon.svg" class="img-item"> {{
                translate('shipment_progress') }} <span v-if="progress_orders_notification > 0">({{
                  progress_orders_notification }})</span>
              </li>
            </router-link>
            <router-link to="/delivered-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/Check.svg" class="img-item"> {{
                translate('shipment_deleivred') }}
              </li>
            </router-link>
            <router-link to="/commissions" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash4.png" class="img-item"
                  style="margin-right: 12px;">
                {{
                  translate('commissions') }}</li>
            </router-link>
            <router-link to="/new_order" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash5.png" class="img-item"
                  style="margin-right: 12px;">
                {{
                  translate('new_shipment') }}</li>
            </router-link>
            <router-link v-if="company.bonus" to="/mybonus" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/bonus_icon.svg" class="img-item" style="margin-right: 12px;">
                {{translate('bonus') }} 
                <span style="margin-left: 5px; background-color: #E5097F; color: white; padding: 3px 8px 3px 8px; border-radius: 20px; font-weight: bold;">{{ company.bonus_points }} pts</span>
              </li>
            </router-link>
          </ul>

          <ul class="nav flex-column" style="margin-top: 400px; padding-left: 5%; margin-bottom: 10px;">
            <router-link to="/company" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash6.png" class="img-item"> {{
                translate('my_company')
                }}</li>
            </router-link>
            <li class="nav-item custom" @click="logout()"><img src="../assets/img/dash8.png" class="img-item"> {{
              translate('sign_out') }}</li>
          </ul>
        </div>
      </nav>

      <!-- Content Area -->
      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" style=" background-color: #F7F7F8;">

        <div class="row justify-content-between" style="padding-right: 50px; padding-left: 50px; margin-top: 30px;">
          <div class="col-auto">
            <p style="font-weight: bold; color: black;">{{ translate('hello') }} {{ user.first_name }} <br> <span
                style="font-weight: 400;">
                {{ translate('welcome_back') }}
              </span></p>
          </div>
          <router-link to="/company" class="col-auto" style="cursor: pointer;">
            <p style="color: #E5097F; font-weight: bold;"> <i class="fa fa-building-o" aria-hidden="true"
                style="margin-right: 10px; font-size: 25px;"></i> {{ company.company_name }} </p>
          </router-link>
        </div>

        <div class="row justify-content-center" style="margin-top: 20px; margin-bottom: 100px;">
          <!-- First Table -->
          <div class="col-12 text-center">
            <div>
              <div class="row justify-content-center">
                <div class="col-md-8 mb-4">
                  <div class="card mb-4" style="border-radius: 20px;">
                    <div class="card-header py-3"
                      style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                      <h5 class="mb-0" style="color: white; font-weight: bold;"><img
                          src="../assets/img/shipment_info_icon.svg"
                          style="transform: scale(1.5); margin-right: 8px; margin-left: 5px;"> {{
                            translate('printing_documents') }}</h5>
                    </div>
                    <div class="card-body">
                      <div class="card-body" style="text-align: left; padding-left: 30px;">
                        <template v-if="shipment.proforma_exist">
                          <p style="color: black; font-weight: 600; line-height: 30pt;">
                            <span>1. {{ translate('print_shippinglabel') }}</span>
                            <template v-if="shipment.shippinglabel_pdf">
                              <button class="btn btn-primary printbtn" @click="printshippinglabel"><i
                                  class="fa fa-print"></i> {{ translate('print') }}</button>
                            </template>
                            <template v-else>
                              <button class="btn btn-primary printbtn-deactivate"><i class="fa fa-print"></i> {{
                                translate('print') }} </button>
                            </template>
                            <br>
                            <span>2. {{ translate('print_saleticket') }}</span>
                            <template v-if="shipment.saleticket_pdf">
                              <button class="btn btn-primary printbtn" @click="printsaleticket"><i
                                  class="fa fa-print"></i> {{ translate('print') }}</button>
                            </template>
                            <template v-else>
                              <button class="btn btn-primary printbtn-deactivate"><i class="fa fa-print"></i> {{
                                translate('print') }}</button>
                            </template>
                            <br>
                            <span>3. {{ translate('print_proformainvoice') }}</span>
                            <template v-if="shipment.proforma_pdf">
                              <button class="btn btn-primary printbtn" @click="printproformainvoice"><i
                                  class="fa fa-print"></i> {{ translate('print') }}</button>
                            </template>
                            <template v-else>
                              <button class="btn btn-primary printbtn-deactivate"><i class="fa fa-print"></i> {{
                                translate('print') }}</button>
                            </template>
                            <br>
                            <span>4. {{ translate('pack_itmes') }}</span><br>
                            <span>5. {{ translate('affix_itmes1') }}</span><br>
                          </p>
                        </template>
                        <template v-else>
                          <p style="color: black; font-weight: 600; line-height: 30pt;">
                            <span>1. {{ translate('print_shippinglabel') }}</span>
                            <template v-if="shipment.shippinglabel_pdf">
                              <button class="btn btn-primary printbtn" @click="printshippinglabel"><i
                                  class="fa fa-print"></i> {{ translate('print') }}</button>
                            </template>
                            <template v-else>
                              <button class="btn btn-primary printbtn-deactivate"><i class="fa fa-print"></i> {{
                                translate('print') }}</button>
                            </template>
                            <br>
                            <span>2. {{ translate('print_saleticket') }}</span>
                            <template v-if="shipment.saleticket_pdf">
                              <button class="btn btn-primary printbtn" @click="printsaleticket"><i
                                  class="fa fa-print"></i> {{ translate('print') }}</button>
                            </template>
                            <template v-else>
                              <button class="btn btn-primary printbtn-deactivate"><i class="fa fa-print"></i> {{
                                translate('print') }}</button>
                            </template>
                            <br>
                            <span>3. {{ translate('pack_itmes') }}</span><br>
                            <span>4. {{ translate('affix_itmes2') }}</span><br>
                          </p>
                        </template>
                      </div>
                    </div>
                    <div>
                      <p style="color: red; font-weight: 600;">{{ translate('contact_us_problem') }}</p>
                      <p style="color: #E5097F; font-weight: 600; margin-top: 10px;"><i class="fa fa-phone"
                          style="font-size: 18px;"></i> {{
                            translate('contact_us_problem_phone') }}</p>
                    </div>
                    <div style="margin-bottom: 20px; margin-top: 20px;">
                      <router-link to="/progress-orders"
                        style="font-size: 18px; padding-left: 35px; padding-right: 35px;"
                        class="btn btn-primary printbtn"><i class="fa fa-check-circle"></i> {{ translate('complete')
                        }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js/auto';
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import Swal from 'sweetalert2'
import '@vuepic/vue-datepicker/dist/main.css';
import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';

export default {
  name: 'Dashboard',
  mixins: [en, fr, nl],
  components: {
    Datepicker
  },
  setup() {
    const date = ref();
    const dateFormat = 'dd-MM-yyyy';

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Check if the current hour is past 18:00
    const currentHour = new Date().getHours();
    const isPast17 = currentHour >= 17;

    if (isPast17) {
      today.setDate(today.getDate() + 1);
    }

    // Calculate the disabled dates (weekends)
    const disabledDates = (date) => {
      const dayOfWeek = date.getDay();
      return dayOfWeek === 0 || dayOfWeek === 6;
    };

    const maxDate = new Date(today);
    maxDate.setDate(maxDate.getDate() + 7);

    return {
      date,
      dateFormat,
      today,
      maxDate,
      disabledDates
    };
  },
  data() {
    return {
      user: {},
      today_shipments: "",
      progress_shipments: "",
      delivered_shipments: "",
      all_shipments: "",
      shipments_statistics: [],
      commissions_statistics: [],
      commission_amount: "",
      shipments: [],
      company: {},
      selectedShipment: null,
      selectedDate: "",
      shipment_id: "",
      delivery_date: "",
      transporter: "",
      availableStartTimes: [],
      availableCloseTimes: [],
      ready_time: "",
      close_time: "",
      shipment: {},
      pending_orders_notification: 0,
      progress_orders_notification: 0
    }
  },
  watch: {
    selectedDate(newVal) {
      this.calculateAvailableStartTimes();
    },
    ready_time(newVal) {
      this.updateCloseTimeOptions();
    },
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
    specialPostalCodes() {
      return [1000, 1030, 1040, 1050, 1060, 1070, 1080, 1081, 1082, 1083, 1090, 1140, 1150, 1160, 1170, 1180, 1190, 1200];
    },
  },
  async created() {

    this.$store.commit('setIsLoading', true)
    await axios.get('/api/v1/users/me/')
      .then(async response => {
        this.user = response.data
        await this.getServicePoint(this.user.id)
      })
      .catch(error => {
        console.log(error)
      })
    this.$store.commit('setIsLoading', false)

  },
  async mounted() {
    document.title = 'Validate Shipment | Orders';
    const element = this.$refs.scrollTarget;
    element.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, 0);

    this.shipment_id = this.$route.params.shipment_id
    this.getShipmentDocuments()
    await this.getNotifications()


    //----------------pickup date------------------------
    if (this.specialPostalCodes.includes(parseInt(this.$store.state.shipment.sender.postal_code))) {
      const belgiumTimezone = 'Europe/Brussels';
      const optionsss = { timeZone: belgiumTimezone };
      const now = new Date().toLocaleString('en-US', optionsss);
      const currentHour = new Date(now).getHours();
      const currentMinute = new Date(now).getMinutes();
      const currentDayOfWeek = new Date(now).getDay();

      const minDate = new Date();

      if (currentHour > 16 || (currentHour === 16 && currentMinute >= 30)) {
        if (currentDayOfWeek === 5) {
          minDate.setDate(minDate.getDate() + 3);
        } else if (currentDayOfWeek === 6) {
          minDate.setDate(minDate.getDate() + 2);
        } else {
          minDate.setDate(minDate.getDate() + 1);
        }
      }

      minDate.setHours(0, 0, 0, 0);

      this.today = minDate;

      if (currentHour > 16 || (currentHour === 16 && currentMinute >= 30)) {
        this.selectedDate = minDate;
      }
    } else {
      const belgiumTimezone = 'Europe/Brussels';
      const optionsss = { timeZone: belgiumTimezone };
      const now = new Date().toLocaleString('en-US', optionsss);
      const currentHour = new Date(now).getHours();
      const currentMinute = new Date(now).getMinutes();
      const currentDayOfWeek = new Date(now).getDay();

      const minDate = new Date();

      if (currentHour > 15 || (currentHour === 15 && currentMinute >= 30)) {
        if (currentDayOfWeek === 5) {
          minDate.setDate(minDate.getDate() + 3);
        } else if (currentDayOfWeek === 6) {
          minDate.setDate(minDate.getDate() + 2);
        } else {
          minDate.setDate(minDate.getDate() + 1);
        }
      }

      minDate.setHours(0, 0, 0, 0);

      this.today = minDate;

      if (currentHour > 15 || (currentHour === 15 && currentMinute >= 30)) {
        this.selectedDate = minDate;
      }
    }

  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    async getNotifications() {
      await axios.post('/api/v1/get-notifications/')
        .then(response => {
          this.pending_orders_notification = response.data['pending_orders']
          this.progress_orders_notification = response.data['progress_orders']
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    async bookShipment() {
      this.$store.commit('setIsLoading', true)

      const date = new Date(this.selectedDate);
      const toTwoDigits = (num) => {
        return num < 10 ? '0' + num : '' + num;
      };

      const day = toTwoDigits(date.getDate());
      const month = toTwoDigits(date.getMonth() + 1);
      const year = date.getFullYear();
      const selectedDate = `${year}-${month}-${day}`;

      const dataserv = {
        'shipment_id': this.shipment_id,
        "ship_date": selectedDate,
        "transporter": this.transporter,
        "delivery_time": this.delivery_date,
        "ready_time": this.ready_time,
        "close_time": this.close_time
      }

      await axios.post('/api/v1/book-shipment/', dataserv)
        .then(async response => {
          await this.ValidateShipment(this.shipment_id)
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    async getDelivery_time() {
      this.$store.commit('setIsLoading', true)

      const date = new Date(this.selectedDate);
      const toTwoDigits = (num) => {
        return num < 10 ? '0' + num : '' + num;
      };

      const day = toTwoDigits(date.getDate());
      const month = toTwoDigits(date.getMonth() + 1);
      const year = date.getFullYear();
      const selectedDate = `${year}-${month}-${day}`;

      const dataserv = {
        'shipment_id': this.shipment_id,
        "ship_date": selectedDate,
      }

      await axios.post('/api/v1/get-delivery-date/', dataserv)
        .then(response => {
          this.delivery_date = response.data['delivery_time']
          this.transporter = response.data['transporter']
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    formattedDeliveryTime() {
      if (this.delivery_date) {
        const dateParts = this.delivery_date.split('-');
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        return formattedDate;
      }
      return '';
    },
    calculateAvailableStartTimes() {
      this.availableStartTimes = [];
      this.availableCloseTimes = [];

      const date = new Date(this.selectedDate);
      const toTwoDigits = (num) => {
        return num < 10 ? '0' + num : '' + num;
      };
      const day = toTwoDigits(date.getDate());
      const month = toTwoDigits(date.getMonth() + 1);
      const year = date.getFullYear();
      const selectedDate = `${year}-${month}-${day}`;

      // Getting and formatting today's date
      const todayyy = new Date();
      const todayDay = toTwoDigits(todayyy.getDate());
      const todayMonth = toTwoDigits(todayyy.getMonth() + 1);
      const todayYear = todayyy.getFullYear();
      const todayFormatted = `${todayYear}-${todayMonth}-${todayDay}`;

      if (selectedDate === todayFormatted) {

        const belgiumTimezone = 'Europe/Brussels';
        const optionsss = { timeZone: belgiumTimezone };
        const now = new Date().toLocaleString('en-US', optionsss);
        const currentHour = new Date(now).getHours();
        const currentMinute = new Date(now).getMinutes();

        if (currentMinute < 30) {
          for (let i = currentHour + 1; i < 17; i++) {
            this.availableStartTimes.push(`${i}:00`);
            this.availableStartTimes.push(`${i}:30`);
          }
        } else {
          this.availableStartTimes.push(`${currentHour + 1}:30`);
          for (let i = currentHour + 2; i < 17; i++) {
            this.availableStartTimes.push(`${i}:00`);
            this.availableStartTimes.push(`${i}:30`);
          }
        }
      } else {
        for (let i = 9; i < 17; i++) {
          if (i === 9) {
            this.availableStartTimes.push(`0${i}:00`);
            this.availableStartTimes.push(`0${i}:30`);
          } else {
            this.availableStartTimes.push(`${i}:00`);
            this.availableStartTimes.push(`${i}:30`);
          }

        }
      }
    },
    updateCloseTimeOptions() {
      this.availableCloseTimes = [];
      if (this.ready_time) {
        const startTime = this.ready_time.split(':');
        let hour = parseInt(startTime[0]);
        let minutes = parseInt(startTime[1]);

        if (minutes === 30) {
          for (let i = hour + 2; i <= 18; i++) {
            this.availableCloseTimes.push(`${i}:00`);
            if (i != 18) {
              this.availableCloseTimes.push(`${i}:30`);
            }
          }
        } else {
          for (let i = hour + 1; i <= 17; i++) {
            this.availableCloseTimes.push(`${i}:30`);
            this.availableCloseTimes.push(`${i + 1}:00`);
          }

        }
      }
    },
    checkFields() {
      if (!this.selectedDate) {
        toast({
          message: this.translate('ship_date_alert'),
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          duration: 4000,
          position: 'top-right',
        })
      }
    },
    checkFields2() {
      if (!this.ready_time || !this.close_time) {
        toast({
          message: this.translate('pickup_time_alert'),
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          duration: 4000,
          position: 'top-right',
        })
      }
    },
    async getServicePoint(user_id) {
      await axios.post('/api/v1/getservicepoint/')
        .then(response => {
          this.company = response.data
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    async getShipmentDocuments() {
      await axios.post(`/api/v1/get-shipment-documents/${this.shipment_id}/`)
        .then(response => {
          this.shipment = response.data['shipment']
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    AskValidation() {
      Swal.fire({
        icon: 'question',
        title: this.translate('shipment_validation'),
        text: this.translate('question_validation'),
        showCancelButton: true,
        confirmButtonText: this.translate('Yes'),
        cancelButtonText: this.translate('No'),
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          this.bookShipment()
        }
      });
    },
    async ValidateShipment(shipment_id) {
      this.$store.commit('setIsLoading', true)
      await axios.post(`/api/v1/validate-shipment/${shipment_id}/`)
        .then(response => {
          toast({
            message: this.translate('shipment_validated'),
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right',
          })
          this.$router.push(`/print-documents/${shipment_id}`)
        })
        .catch(error => {
          toast({
            message: "Something went wrong",
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right',
          })
        })
      this.$store.commit('setIsLoading', false)
    },
    async printshippinglabel() {
      const url = `https://demo.api.eurekali-point.com/api/v1/shhippinglabel/print/${this.shipment_id}/`;
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = `shipping_label_${this.shipment_id}.pdf`;
        const fileURL = window.URL.createObjectURL(blob);

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileURL;
        document.body.appendChild(iframe);

        iframe.onload = function () {
          iframe.contentWindow.print();
        };
      } catch (error) {
        console.error('Error printing shipping label:', error);
      }
    },
    async printsaleticket() {
      const url = `https://demo.api.eurekali-point.com/api/v1/saleticket/print2/${this.shipment_id}/`;
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = `sale_ticket_${this.shipment_id}.pdf`;
        const fileURL = window.URL.createObjectURL(blob);

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileURL;
        document.body.appendChild(iframe);

        iframe.onload = function () {
          iframe.contentWindow.print();
        };
      } catch (error) {
        console.error('Error printing sale ticket:', error);
      }
    },
    async printproformainvoice() {
      const url = `https://demo.api.eurekali-point.com/api/v1/proformainvoice/print/${this.shipment_id}/`;
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = `proforma_invoice_${this.shipment_id}.pdf`;
        const fileURL = window.URL.createObjectURL(blob);

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = fileURL;
        document.body.appendChild(iframe);

        iframe.onload = function () {
          iframe.contentWindow.print();
        };
      } catch (error) {
        console.error('Error printing proforma invoice:', error);
      }
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = ""
      sessionStorage.removeItem("token")
      this.$store.commit('removeToken')
      this.$store.commit('removeAgent')
      this.$store.commit('clearShipment')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.custom {
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding-bottom: 10px;

}

.custom:hover {
  color: #E5097F;
  font-weight: bold;
}


.active-item {
  color: #E5097F;
  font-weight: bold;
}

.img-item {
  margin-right: 8px;
}

.red-header tr {
  background-color: red;
  color: white;
}

.btn-new-ship {
  color: #E5097F;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #E5097F;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.btn-new-ship:hover {
  background-color: #9b0656;
  color: white;
  border: 2px solid #9b0656;
}


.btn-more {
  color: #fff;
  font-weight: bold;
  background-color: #E5097F;
  border: 2px solid #E5097F;
  margin-left: 10px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btn-more:hover {
  background-color: #9b0656;
  border: 2px solid #9b0656;
}

.btn-request {
  background-color: #BCF2C0;
  margin-left: 12px;
  border-radius: 20px;
  border: 2px solid #37B980;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: bold;
}

.btn-request:hover {
  background-color: #37B980;
  color: white;
}


.box-shadow-custom {
  box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}


.bg-progress {
  background-color: #D5CB41;
}

.bg-delivered {
  background-color: #41D54B;
}

.validate-btn {
  padding: 8px;
  background-color: #14a60f;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #14a60f;
  user-select: none;
}

.validate-btn:hover {
  background-color: #0d8109;
  border: 1px solid #0d8109;
}

.modify-btn {
  padding: 8px;
  background-color: #0e5dc5;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #0e5dc5;
  user-select: none;
}

.modify-btn:hover {
  background-color: #0c4896;
  border: 1px solid #0c4896;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-body {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 45%;
}

.modal-content {
  background-color: #FEEEF6;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.printbtn {
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
  background-color: #E5097F;
  border: none;
  font-weight: 600;
  margin-left: 20px;
}

.printbtn-deactivate {
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
  background-color: grey;
  border: none;
  font-weight: 600;
  margin-left: 20px;
  cursor: not-allowed;
}

.printbtn:hover {
  color: white;
  background-color: #9b0656;
}

.printbtn:focus {
  color: white;
  background-color: #9b0656;
}
</style>