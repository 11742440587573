<template>
  <div ref="scrollTarget"></div>
  <div class="container-fluid" style="height: 100%;">
    <div class="row">
      <!-- Left Side Menu -->
      <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar">
        <div class="position-sticky" style="margin-top: 50px;">
          <ul class="nav flex-column" style="padding-left: 5%; margin-top: 10px;">
            <router-link to="/dashboard" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash1.png" class="img-item" style="margin-right: 12px;">
                {{
                  translate('dashboard')
                }}</li>
            </router-link>
            <router-link to="/orders-validate" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/wait_icon.svg" class="img-item"> {{
                translate('shipments_to_validate') }}
              </li>
            </router-link>
            <router-link to="/progress-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/progress_icon.svg" class="img-item"> {{
                translate('shipment_progress') }}
              </li>
            </router-link>
            <router-link to="/commissions" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash4.png" class="img-item" style="margin-right: 12px;">
                {{
                  translate('commissions') }}</li>
            </router-link>
            <router-link to="/new_order" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash5.png" class="img-item" style="margin-right: 12px;">
                {{
                  translate('new_shipment') }}</li>
            </router-link>
          </ul>

          <ul class="nav flex-column" style="margin-top: 400px; padding-left: 5%; margin-bottom: 10px;">
            <router-link to="/company" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash6.png" class="img-item"> {{ translate('my_company')
              }}</li>
            </router-link>
            <li class="nav-item custom" @click="logout()"><img src="../assets/img/dash8.png" class="img-item"> {{
              translate('sign_out') }}</li>
          </ul>
        </div>
      </nav>

      <!-- Content Area -->
      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" style=" background-color: #F7F7F8;">

        <div class="row justify-content-between" style="padding-right: 50px; padding-left: 50px; margin-top: 30px;">
          <div class="col-auto">
            <p style="font-weight: bold; color: black;">{{ translate('hello') }} {{ user.first_name }} <br> <span
                style="font-weight: 400;">
                {{ translate('welcome_back') }}
              </span></p>
          </div>
          <router-link to="/company" class="col-auto" style="cursor: pointer;">
            <p style="color: #E5097F; font-weight: bold;"> <i class="fa fa-building-o" aria-hidden="true"
                style="margin-right: 10px; font-size: 25px;"></i> {{ company.company_name }} </p>
          </router-link>
        </div>



        <div class="row justify-content-center" style="margin-top: 20px; margin-bottom: 100px;">
          <!-- First Table -->
          <div class="col-12 text-center">
            <template v-if="shipments.length">
              <div class="table-responsive-lg">

                <table class="table table-hover table-striped table-bordered table-responsive">
                  <thead class="table-dark">
                    <tr>
                      <!-- Add the 'white-space: nowrap;' and 'text-align: center;' styles to table headers (th) -->
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-left-radius: 12px;">
                        {{ translate('client') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('request_date') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('ship_to') }} </th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('package_type') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('weight') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('amount') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">
                        {{ translate('verification_code') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-right-radius: 12px;">
                        {{ translate('cancel') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="shipment in shipments" :key="shipment.shipment_id">
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.client }}</td>
                      <td style="vertical-align: middle; text-align: center; "> {{ shipment.order_date }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.shipment_country }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.shipment_package }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.shipment_weight }}
                      </td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.price }} &euro;
                      </td>
                      <td style="vertical-align: middle; text-align: center;"> <span class="badge bg-success rounded-pill"
                          style="font-size: 1em;">{{ shipment.code }}</span></td>

                      <td
                        style="vertical-align: middle; text-align: center; padding: 7px; text-align: center; color: #E5097F; font-size: 20px;">
                        <button class="btn btn-danger" style="padding: 0px; padding-left: 10px; padding-right: 10px;"
                          @click="cancelCashcode(shipment.id)">{{ translate('cancel') }}</button>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="container"
                style="text-align: center; color: white; font-weight: bold; font-size: 1.5em; background-color: #F1A8D0; padding: 60px; border-radius: 20px;">
                {{ translate('no_codes') }}
              </div>
            </template>
          </div>

        </div>



      </main>
    </div>
  </div>
</template>
        
<script>
import axios from 'axios'
import Chart from 'chart.js/auto';
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import Swal from 'sweetalert2'

export default {
  name: 'Dashboard',
  mixins: [en, fr, nl],
  data() {
    return {
      user: {},
      today_shipments: "",
      progress_shipments: "",
      delivered_shipments: "",
      all_shipments: "",
      today_date: "",
      shipments_statistics: [],
      commissions_statistics: [],
      commission_amount: "",
      shipments: [],
      company: {},
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
  },
  async created() {

    this.$store.commit('setIsLoading', true)
    await axios.get('/api/v1/users/me/')
      .then(async response => {
        this.user = response.data
        await this.getServicePoint(this.user.id)
      })
      .catch(error => {
        console.log(error)
      })
    this.$store.commit('setIsLoading', false)


    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    this.today_date = `${day}/${month}/${year}`;

  },
  async mounted() {
    document.title = 'Dashboard | Orders';
    const element = this.$refs.scrollTarget;
    element.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, 0);

    this.$store.commit('setIsLoading', true)
    await this.fetchShipments()
    this.$store.commit('setIsLoading', false)

  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    async fetchShipments() {
      try {
        const response = await axios.post('/api/v1/get-cash-codes/');
        this.shipments = response.data['cash_codes'];
      } catch (error) {
        console.error('Error fetching shipments:', error);
      }
    },
    goToShipmentPage(shipmentId) {
      this.$router.push(`/track/${shipmentId}`);
    },
    async getServicePoint(user_id) {
      await axios.post('/api/v1/getservicepoint/')
        .then(response => {
          this.company = response.data
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    async printProformaInvoice(shipment_id) {
      const url = `https://demo.api.eurekali-point.com/api/v1/proformainvoice/print/${shipment_id}/`;
      window.open(url, '_blank');
    },
    async printsaleticket(saleticket_id) {
      const url = `https://demo.api.eurekali-point.com/api/v1/saleticket/print/${saleticket_id}/`;
      window.open(url, '_blank');
    },
    AskValidation(shipment_id) {
      Swal.fire({
        icon: 'question',
        title: this.translate('shipment_validation'),
        text: this.translate('question_validation'),
        showCancelButton: true,
        confirmButtonText: this.translate('Yes'),
        cancelButtonText: this.translate('No'),
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          this.ValidateShipment(shipment_id)
        }
      });
    },
    async ValidateShipment(shipment_id) {
      this.$store.commit('setIsLoading', true)
      await axios.post(`/api/v1/validate-shipment/${shipment_id}/`)
        .then(response => {
          this.fetchShipments()
          toast({
            message: this.translate('shipment_validated'),
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right',
          })
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
      this.$store.commit('setIsLoading', false)
    },
    async cancelCashcode(id) {
      this.$store.commit('setIsLoading', true)
      await axios.post(`/api/v1/cancel-cash-code/${id}/`)
        .then(response => {
          this.fetchShipments()
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
      this.$store.commit('setIsLoading', false)
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = ""
      sessionStorage.removeItem("token")
      this.$store.commit('removeToken')
      this.$store.commit('removeAgent')
      this.$store.commit('clearShipment')
      this.$router.push('/login')
    }
  }
}
</script>
        
<style scoped>
.custom {
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding-bottom: 10px;

}

.custom:hover {
  color: #E5097F;
  font-weight: bold;
}


.active-item {
  color: #E5097F;
  font-weight: bold;
}

.img-item {
  margin-right: 8px;
}

.red-header tr {
  background-color: red;
  color: white;
}

.btn-new-ship {
  color: #E5097F;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #E5097F;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.btn-new-ship:hover {
  background-color: #9b0656;
  color: white;
  border: 2px solid #9b0656;
}


.btn-more {
  color: #fff;
  font-weight: bold;
  background-color: #E5097F;
  border: 2px solid #E5097F;
  margin-left: 10px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btn-more:hover {
  background-color: #9b0656;
  border: 2px solid #9b0656;
}

.btn-request {
  background-color: #BCF2C0;
  margin-left: 12px;
  border-radius: 20px;
  border: 2px solid #37B980;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: bold;
}

.btn-request:hover {
  background-color: #37B980;
  color: white;
}


.box-shadow-custom {
  box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}


.bg-progress {
  background-color: #D5CB41;
}

.bg-delivered {
  background-color: #41D54B;
}

.validate-btn {
  padding: 0;
  background-color: #14a60f;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #14a60f;
  user-select: none;
}

.validate-btn:hover {
  background-color: #0d8109;
  border: 1px solid #0d8109;
}

.modify-btn {
  padding: 0;
  background-color: #0e5dc5;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #0e5dc5;
  user-select: none;
}

.modify-btn:hover {
  background-color: #0c4896;
  border: 1px solid #0c4896;
}</style>
        